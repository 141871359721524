import React from "react";
import Section from "../../Components/Section";
import CategoryBtns from "../../Components/Categories";
// import { Container } from "react-bootstrap";
import Container from "../../Components/Container";
import styles from "./styles.module.scss";

const Categories = ({ className, ...props }) => {
  return (
    <Container className={`${className}`}>
      <Section className={`${styles.categories}`}>
        <CategoryBtns section={props.section} />
      </Section>
    </Container>
  );
};
 
export default Categories;
